import { Box } from '@chakra-ui/react';
import React from 'react';

import { HEADER_SIZE } from '../../../theme/chakra/layout';
import { LogoMark, LogoWord } from './Logo';

const LeftLogo: React.FC = () => {
  return (
    <Box flex="1 1 auto" h={HEADER_SIZE}>
      <LogoMark
        // small
        display={{
          base: 'block',
          sm: 'none',
        }}
      />
      <LogoWord
        // big
        display={{
          base: 'none',
          sm: 'block',
        }}
      />
    </Box>
  );
};

export default LeftLogo;
