'use client';

import { Box, Flex, Spacer } from '@chakra-ui/react';
import React from 'react';

import { useColorScheme } from '../../theme/chakra/colors';
import { HEADER_SIZE, LOGO_WIDTH, PADDING } from '../../theme/chakra/layout';
import Header from './Header/Header';

const MainLayout: React.FC<React.PropsWithChildren> = ({ children }) => {
  const bgColors = useColorScheme('makeBackgroundColors');

  return (
    <Flex minH="100vh" direction="column" align="center" justify="center" h="full" {...bgColors}>
      <Header />

      {/* TODO: remove dead code below */}
      {/* <Spacer
        display={{
          base: 'none',
          sm: 'block',
        }}
      /> */}

      <Box
        w="100%"
        h="100%"
        marginTop={{
          base: '4', // unique for narrow viewport
          sm: '0',
        }}
        padding={PADDING}
      >
        {children ?? null}
      </Box>

      <Spacer />
      <Box padding={PADDING} w="100%" minH={HEADER_SIZE}>
        <Box w={LOGO_WIDTH} h={HEADER_SIZE} /* fake logo spacer*/ />
      </Box>
    </Flex>
  );
};

export default MainLayout;
