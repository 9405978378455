// constants for layout dimensions
// some are just simple pixel sizes,
// others are responsive/relative chakra sizes

const HEADER_SIZE = '28px';
const LOGO_WIDTH = '320px';
const PADDING_BIG = '12';
const PADDING_MORE = { base: '6', md: '10' };
const PADDING = { base: '3', md: '6' };
const PADDING_INPUT = { base: '4', lg: '6', xl: '8' };
const PADDING_HALF = { base: '2', md: '4' };
const INPUT_RADIUS = { base: '6', lg: '8' };
const CARD_RADIUS = '8px';

export {
  HEADER_SIZE,
  INPUT_RADIUS,
  LOGO_WIDTH,
  PADDING,
  PADDING_BIG,
  PADDING_HALF,
  PADDING_INPUT,
  PADDING_MORE,
  CARD_RADIUS,
};
