import { Link } from '@chakra-ui/react';
import NextLink from 'next/link';
import React from 'react';

import { useColorScheme } from '../../theme/chakra/colors';

interface BaseLinkProps extends React.PropsWithChildren {
  text?: string;
  onClick: () => void;
}

/**
 * A basic link with a click handler
 */
const BaseLink: React.FC<BaseLinkProps> = ({
  children /** @type {React.Prop} */,
  text /** @type {string} text to display, overridden by children */,
  onClick /** @type {() => {}} internal route */,
}) => {
  const linkColors = useColorScheme('makeLinkColors');
  return (
    <Link {...linkColors} as={NextLink} fontWeight="medium" onClick={onClick}>
      {children ? children : text}
    </Link>
  );
};

export default BaseLink;
