import { Stack } from '@chakra-ui/react';

import { PADDING } from '../../../theme/chakra/layout';
import { InternalLink } from '../../Links';
import LeftLogo from './LeftLogo';
import RightMenu from './RightMenu';

export default function Header() {
  return (
    <Stack direction="row" padding={PADDING} w="100%" bg="white">
      <InternalLink href="/">
        <LeftLogo />
      </InternalLink>
      <RightMenu />
    </Stack>
  );
}
