import { Link } from '@chakra-ui/react';
import React from 'react';

import { useColorScheme } from '../../theme/chakra/colors';

interface ExternalLinkProps extends React.PropsWithChildren {
  text?: string;
  href: string;
}

/**
 * A link to an external resource (URL), NOT a internal route
 */
const ExternalLink: React.FC<ExternalLinkProps> = ({
  children /** @type {React.Children} */,
  text /** @type {string} text to display, overridden by children */,
  href /** @type {string} url href, http:// */,
}) => {
  const linkColors = useColorScheme('makeLinkColors');
  return (
    <Link {...linkColors} fontWeight="medium" isExternal href={href}>
      {children ? children : text}
    </Link>
  );
};

export default ExternalLink;
