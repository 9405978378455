'use client';

import { Image } from '@chakra-ui/image';
import { Box } from '@chakra-ui/layout';
import { useMemo } from 'react';

import useAuth from '../../hooks/useAuth';
import { PADDING } from '../../theme/chakra/layout';
import { checkHasBrandingData } from '../../utils/utils';

export default function SunnyImage() {
  const { brandingData } = useAuth();

  const hasBrandingData = useMemo(() => {
    return checkHasBrandingData(brandingData);
  }, [brandingData]);

  if (hasBrandingData) {
    return null;
  }

  return (
    <Box w="100%" h="1px" pr={PADDING}>
      <Box w="100%" h="1px" position="relative">
        <Box position="absolute" right="0" top="-20px">
          <Image w="120px" src="images/sunny-left.png" transform="scaleX(-1)" alt="" />
        </Box>
      </Box>
    </Box>
  );
}
