'use client';

import { ChevronDownIcon } from '@chakra-ui/icons';
import {
  Box,
  Button,
  Menu,
  MenuButton,
  MenuDivider,
  MenuGroup,
  MenuItem,
  MenuItemOption,
  MenuList,
  MenuOptionGroup,
  Spacer,
  Stack,
  Switch,
  Text,
} from '@chakra-ui/react';

import useAppLanguage from '../../../hooks/useAppLanguage';
import { useColorMode, useColorScheme } from '../../../theme/chakra/colors';
import { HEADER_SIZE } from '../../../theme/chakra/layout';

const RightMenu = () => {
  const { isDark, toggleColorMode } = useColorMode();
  const inputColors: Record<string, string> = useColorScheme('makeInputColors');
  const { translate, languages, currentLanguage, changeLanguage } = useAppLanguage();

  const darkModeFeatureReady = false;
  return (
    <Box flex="1 1 auto" h={HEADER_SIZE} display="flex" justifyContent="flex-end">
      <Menu placement="bottom-end">
        <MenuButton
          as={Button}
          rightIcon={<ChevronDownIcon />}
          size="sm"
          h={HEADER_SIZE}
          fontWeight="normal"
          {...inputColors}
        >
          <Text
            display={{
              base: 'block',
              sm: 'none',
            }}
          >
            {currentLanguage.code}
          </Text>
          <Text
            display={{
              base: 'none',
              sm: 'block',
            }}
          >
            {currentLanguage.label}
          </Text>
        </MenuButton>
        <MenuList minWidth="min">
          <MenuOptionGroup
            defaultValue={currentLanguage.code}
            type="radio"
            onChange={(value) => {
              changeLanguage(value as string);
            }}
          >
            {Object.entries(languages).map(([code, { label }]) => {
              return (
                <MenuItemOption key={code} value={code}>
                  {label}
                </MenuItemOption>
              );
            })}
          </MenuOptionGroup>
          {darkModeFeatureReady && (
            <>
              <MenuDivider />
              <MenuGroup>
                <MenuItem
                  closeOnSelect={false}
                  onClick={(e) => {
                    e.preventDefault();
                    e.stopPropagation();
                    toggleColorMode();
                  }}
                >
                  <Stack direction="row" align="center">
                    <Text>{translate('Dark Mode')}</Text>
                    <Spacer minWidth="1" />
                    <Switch size="sm" isReadOnly isChecked={isDark()} />
                  </Stack>
                </MenuItem>
              </MenuGroup>
            </>
          )}
        </MenuList>
      </Menu>
    </Box>
  );
};

export default RightMenu;
